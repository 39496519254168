<template>
	<!-- <div class="tooltip">
        <span class="tooltiptext">{{ text }}</span>
        <slot></slot>
    </div>  -->

	<div class="has-tooltip">
		<span class="tooltipx rounded shadow-lg p-1 bg-gray-800 text-white text-center break-normal">
			{{ text }}
		</span>
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: ["text"],
};
</script>

<style>
.has-tooltip {
	position: relative;
}

.tooltipx {
	@apply invisible absolute z-50;
	opacity: 0;
	transition-duration: 0.2s;
	width: 10em;
	margin-bottom: 3em;
	bottom: 0;
}

.has-tooltip:hover .tooltipx {
	@apply visible z-50;
	opacity: 1;
	transition-duration: 0.5s;
	transition-delay: 0.5s;
	margin-bottom: 2em;
}
</style>
