<template>
	<form @submit.prevent="save" class="form-bare">
		<div v-if="this.saveError" class="text-red-800">Error saving: {{ saveError }}</div>

		<label class="flex flex-col font-bold w-full">
			<span>Name</span>
			<input class="border form-input mt-1 p-2" name="name" v-model="name" placeholder="Search Name" required />
		</label>
		<v-button type="submit" class="btn btn-success" @click="saveSearch" :loading="loading">Save search</v-button>
	</form>
</template>

<script>
import Button from "@/components/ui/Button";

export default {
	components: {
		"v-button": Button,
	},

	data() {
		return {
			name: null,
			saveError: null,
			loading: false,
		};
	},

	props: ["teamId", "projectId", "searchParameters"],

	emits: ["saveResult"],

	methods: {
		async saveSearch() {
			this.loading = true;
			let resource = {
				name: this.name,
				parameters: this.searchParameters.searchParameters,
			};

			let response = null;
			try {
				response = await this.$api
					.resource("prospectProjectSavedSearches", {
						team_id: this.teamId,
						project_type: "prospects",
						project_id: this.projectId,
					})
					.create(resource);
				this.$emit("saveResult", response != null);
				// this.name = null;
			} catch (e) {
				console.log(`Blablah: ${e}`);
				this.saveError = e.response.data.title;
			}

			this.loading = false;
		},
	},

	computed: {},
};
</script>
<style></style>
