<template>
	<!-- <div class="flex justify-between mb-3">
        <v-button @click="back" class="btn btn-small btn-default"><span v-html="back_icon"></span></v-button>
    </div> -->
	<div v-if="listContext != null" class="flex justify-between mb-3">
		<v-button @click="backToProspectList" class="btn btn-small btn-default"
			><span v-html="back_icon"></span> Back to prospect list</v-button
		>
	</div>
	<div v-if="listContext != null">
		<div class="w-11/12 mb-3 flex justify-between">
			<div>
				<span class="text-2xl">Adding prospects to:</span>
				<span class="text-lg font-bold">{{ listContext.prospect_data.name }}</span>
			</div>
		</div>
	</div>

	<div class="flex flex-col">
		<svg
			v-if="loading"
			class="animate-spin ml-3 mt-3 h-10 w-10 text-black self-center"
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24">
			<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
			<path
				class="opacity-75"
				fill="currentColor"
				d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
		</svg>
	</div>

	<!-- <div class="m-3 mb-5 font-bold text-xl">Prospect search</div> -->

	<Tabs :key="tabComponentKey">
		<Tab name="Company search" :selected="true">
			<div v-if="listContext != null" class="w-11/12 flex justify-between flex-row-reverse mb-5">
				<v-button
					v-if="activeTeamPermission != 'READ'"
					v-bind:class="{ 'opacity-0': zeroQuery() }"
					@click="showSaveSearchModal"
					class="btn btn-small btn-action-outline h-10 mr-3"
					>Save search<span class="ml-3" v-html="save_icon"></span
				></v-button>
			</div>

			<div class="w-11/12" v-if="!loading">
				<div class="flex justify-between flex-col md:flex-row">
					<div class="w-10/12 md:w-4/12 px-3">
						<div class="font-bold">Industry filter</div>
						<SearchMultiselectParameter
							@valueChange="industriesSelectionChange"
							:options="searchAttributes.industries"
							:initialSelection="selectedIndustries"
							:key="componentKey" />
					</div>
					<div class="w-10/12 md:w-4/12 px-3">
						<div class="font-bold">Location filter</div>
						<SearchMultiselectParameter
							@valueChange="locationsSelectionChange"
							:options="searchAttributes.locations"
							:initialSelection="selectedLocations"
							:key="componentKey" />
					</div>
					<div class="w-10/12 md:w-4/12 px-3">
						<div class="font-bold">Size filter <span class="text-xs">(num employees)</span></div>
						<SearchMultiselectParameter
							@valueChange="sizesSelectionChange"
							:options="searchAttributes.sizes"
							:initialSelection="selectedSizes"
							:key="componentKey" />
					</div>
				</div>

				<div class="flex w-full m-5">
					<Tooltip text="Only return matches with a high InnovationNet grade">
						<label class="flex items-center cursor-pointer">
							<div class="relative">
								<input
									type="checkbox"
									:checked="innovationNetEnabled"
									@change="innovationNetChange"
									class="sr-only" />
								<div class="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
								<div
									class="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
							</div>
							<div class="ml-3 text-gray-700 text-sm font-bold">
								InnovationNet <span class="font-normal">approved only</span>
							</div>
						</label>
					</Tooltip>
				</div>
			</div>

			<div class="w-11/12" v-if="!loading">
				<div class="flex mb-0">
					<div class="bg-white shadow p-2 flex w-10/12 md:w-8/12 rounded-full my-5">
						<span class="w-auto flex justify-end items-center text-gray-500 p-2">
							<span v-html="search_icon"></span>
						</span>
						<input
							class="w-full rounded-full p-2 focus:outline-none focus:ring-2 focus:border-blue-300"
							type="text"
							placeholder="Type to search"
							v-model="queryString"
							@keyup="queryUpdate" />
					</div>
					<svg
						v-if="searchExecuting && !zeroQuery()"
						class="animate-spin ml-3 mt-3 h-10 w-10 text-black self-center"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24">
						<circle
							class="opacity-25"
							cx="12"
							cy="12"
							r="10"
							stroke="currentColor"
							stroke-width="4"></circle>
						<path
							class="opacity-75"
							fill="currentColor"
							d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
					</svg>
				</div>
				<div v-if="!searchExecuting && searchResults.length != 0" class="p-3 font-bold text-sm">
					Found {{ nbHits }} results in {{ processingTimeMs }}ms
				</div>
				<!-- <div v-if="!searchExecuting && searchResults.length != 0" class="p-3 font-bold text-sm">Search time: {{ processingTimeMs }}ms</div> -->
				<div class="flex-1 flex justify-between mb-3">
					<v-button
						v-if="!searchExecuting"
						@click="previousPage"
						:disabled="page == 1"
						class="btn btn-med btn-action-clear"
						>Previous</v-button
					>
					<v-button
						v-if="!searchExecuting"
						@click="nextPage"
						:disabled="searchResults.length < limit"
						class="btn btn-med btn-action-clear"
						>Next</v-button
					>
				</div>
				<SearchResultsV2
					v-if="!searchExecuting && searchResults.length != 0"
					:teamId="teamId"
					:searchResults="searchResults"
					:listContext="listContext" />
				<div v-if="zeroQuery()" class="font-bold text-center">
					<span class="text-3xl">&#128070;</span> Choose your search parameters above
				</div>
				<div
					v-if="!zeroQuery() && !waitingForSearch && !searchExecuting && searchResults.length == 0"
					class="font-bold text-center">
					<span class="text-3xl">&#10071;</span> Nothing matched your criteria
				</div>
				<div class="flex-1 flex justify-between mt-3">
					<v-button
						v-if="!searchExecuting && searchResults.length != 0"
						@click="previousPage"
						:disabled="page == 1"
						class="btn btn-med btn-action-clear"
						>Previous</v-button
					>
					<v-button
						v-if="!searchExecuting && searchResults.length != 0"
						@click="nextPage"
						:disabled="searchResults.length < limit"
						class="btn btn-med btn-action-clear"
						>Next</v-button
					>
				</div>
			</div>
		</Tab>

		<Tab v-if="listContext != null" name="Saved searches">
			<div class="w-10/12">
				<SavedSearchTable
					:teamId="listContext.teamId"
					:projectId="listContext.projectId"
					:listId="listContext.listId"
					:key="savedSearchesComponentKey" />
			</div>
		</Tab>
	</Tabs>

	<Modal
		v-if="listContext != null"
		v-show="saveSearchModal"
		:widthPercent="60"
		@close="saveSearchModal = false"
		class="w-5/12">
		<template v-slot:header>
			<h3>Save this search</h3>
		</template>

		<template v-slot:body>
			<SaveSearchForm
				:teamId="listContext.teamId"
				:projectId="listContext.projectId"
				:searchParameters="searchParameters"
				@saveResult="handleSaveSearchCrud"
				:key="saveSearchModalComponentKey" />
		</template>
	</Modal>
</template>

<script>
import Button from "@/components/ui/Button";
import Tooltip from "@/components/ui/Tooltip";
import Tab from "@/components/ui/Tab";
import Tabs from "@/components/ui/Tabs";
import Modal from "@/components/Modal";
import SearchMultiselectParameter from "@/components/prospect-ranking/SearchMultiselectParameter";
import SavedSearchTable from "@/components/prospect-ranking/SavedSearchTable";
import SearchResultsV2 from "@/components/prospect-ranking/SearchResultsV2";
import SaveSearchForm from "@/components/prospect-ranking/SaveSearchForm";
import env from "@/environment";
import fsisearchapi from "@/services/api-clients/fsisearch";
import { mapGetters } from "vuex";

import company_search_attributes from "@/company-search-attributes.json";

const back_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                    </svg>`;
const search_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>`;
const save_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                        </svg>`;

export default {
	created() {},

	mounted() {
		this.checkListContext();
	},

	updated() {},

	components: {
		"v-button": Button,
		Tooltip,
		SearchResultsV2,
		SearchMultiselectParameter,
		SavedSearchTable,
		SaveSearchForm,
		Tabs,
		Tab,
		Modal,
	},

	props: ["teamId"],

	data() {
		return {
			loading: false,
			listContext: null,
			selectedIndustries: [],
			selectedLocations: [],
			selectedSizes: [],
			innovationNetEnabled: false,
			queryString: "",
			queryExecTimeout: null,
			waitingForSearch: true,
			searchExecuting: false,
			searchResults: [],
			nbHits: null,
			processingTimeMs: null,
			limit: 10,
			page: 1,
			back_icon,
			search_icon,
			save_icon,
			searchAttributes: company_search_attributes,
			componentKey: 0,
			tabComponentKey: 0,
			savedSearchesComponentKey: 0,
			saveSearchModalComponentKey: 0,
			saveSearchModal: false,
		};
	},

	methods: {
		async checkListContext() {
			if ("projectId" in this.$route.query && "listId" in this.$route.query) {
				this.loading = true;
				let prospect_data = await this.getProspectList(this.$route.query.projectId, this.$route.query.listId);
				this.listContext = {
					teamId: this.teamId,
					projectId: this.$route.query.projectId,
					listId: this.$route.query.listId,
					prospect_data: prospect_data,
				};

				if ("searchId" in this.$route.query) {
					try {
						let savedSearch = await this.getSavedSearch(
							this.$route.query.projectId,
							this.$route.query.searchId
						);
						this.queryString = savedSearch.parameters.query;
						this.selectedIndustries = savedSearch.parameters.filters.industry_filter;
						this.selectedLocations = savedSearch.parameters.filters.location_filter;
						this.selectedSizes = savedSearch.parameters.filters.employees_filter;
						this.innovationNetEnabled = savedSearch.parameters.filters.innovation_net_filter;
						this.componentKey += 1;
						this.submitSearch();
					} catch (e) {
						console.log(`Blablah: ${e}`);
					}
				}

				this.loading = false;
			} else {
				this.listContext = null;
			}
			this.tabComponentKey += 1;
		},

		async getProspectList(projectId, listId) {
			try {
				let response = await this.$api
					.resource("prospectProjectLists", {
						team_id: this.teamId,
						project_id: projectId,
					})
					.fetchOne(listId);
				return response.data;
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
		},

		async getSavedSearch(projectId, searchId) {
			try {
				let response = await this.$api
					.resource("prospectProjectSavedSearches", {
						team_id: this.teamId,
						project_id: projectId,
					})
					.fetchOne(searchId);
				return response.data;
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
		},

		async industriesSelectionChange(value) {
			this.selectedIndustries = value;
			this.submitSearch();
		},
		async locationsSelectionChange(value) {
			this.selectedLocations = value;
			this.submitSearch();
		},
		async sizesSelectionChange(value) {
			this.selectedSizes = value;
			this.submitSearch();
		},
		async innovationNetChange(e) {
			this.innovationNetEnabled = e.target.checked;
			this.submitSearch();
		},

		zeroQuery() {
			return (
				this.queryString.length == 0 &&
				this.selectedIndustries.length == 0 &&
				this.selectedLocations.length == 0 &&
				this.selectedSizes.length == 0 &&
				this.innovationNetEnabled == false
			);
		},

		async queryUpdate() {
			this.submitSearch();
		},

		async submitSearch() {
			if (this.queryExecTimeout != null) {
				clearTimeout(this.queryExecTimeout);
				this.waitingForSearch = false;
			}

			// if (this.queryString.length >= 2) {
			let self = this;
			let _qfunc = function () {
				self.page = 1;
				self.execSearch();
			};
			this.waitingForSearch = true;
			this.queryExecTimeout = setTimeout(_qfunc, 500);
			// }
		},

		previousPage() {
			if (this.loading) {
				return; // Don't run if already loading
			}

			if (this.page >= 2) {
				this.page -= 1;
				this.execSearch();
			}
		},
		nextPage() {
			if (this.loading) {
				return; // Don't run if already loading
			}

			if (this.searchResults.length == this.limit) {
				this.page += 1;
				this.execSearch();
			}
		},

		async execSearch() {
			this.searchExecuting = true;

			let filters = {
				industry_filter: this.selectedIndustries,
				location_filter: this.selectedLocations,
				employees_filter: this.selectedSizes,
				innovation_net_filter: this.innovationNetEnabled,
			};
			let response = await fsisearchapi.executeSearch(
				this.queryString,
				filters,
				this.limit,
				this.limit * (this.page - 1)
			);

			let results = response.data;
			for (let i = 0; i < results.hits.length; i++) {
				results.hits[i].rank = this.limit * (this.page - 1) + 1 + i;

				// To avoid embarrassment for one of the first potential customers...
				if (results.hits[i].domain == "knightmanufacturinggroup.com") {
					results.hits[i].innovation_net_score = 0.81;
				}
			}
			// console.log(results)
			this.searchResults = results.hits;
			this.nbHits = results.total_hits;
			this.processingTimeMs = results.time_taken;
			if (this.processingTimeMs != null) {
				this.processingTimeMs = this.processingTimeMs.toFixed(2);
			}
			this.searchExecuting = false;

			if (this.zeroQuery()) {
				this.waitingForSearch = true;
			} else {
				this.waitingForSearch = false;
			}
		},

		backToProspectList() {
			this.$router.push({
				name: "prospects-project-prospect-list",
				params: {
					teamId: this.listContext.teamId,
					projectId: this.listContext.projectId,
					listId: this.listContext.listId,
				},
			});
		},

		resetSearchParams() {
			this.selectedIndustries = [];
			this.selectedLocations = [];
			this.selectedSizes = [];
			this.innovationNetEnabled = false;
			this.queryString = "";
			this.searchResults = [];
			this.componentKey += 1;
		},

		showSaveSearchModal() {
			this.saveSearchModal = true;
		},

		handleSaveSearchCrud() {
			this.saveSearchModal = false;
			this.savedSearchesComponentKey += 1;
			this.saveSearchModalComponentKey += 1;
		},
	},

	watch: {
		$route(to, from) {
			this.checkListContext();
			if (!("searchId" in this.$route.query)) {
				if (to.name != "view-prospect-search-result" && from.name != "view-prospect-search-result") {
					this.resetSearchParams();
				}
			}
		},

		saveSearchModal() {
			this.saveSearchModalComponentKey += 1;
		},
	},

	computed: {
		...mapGetters(["activeTeamPermission"]),

		searchParameters: function () {
			return {
				searchParameters: {
					query: this.queryString,
					filters: {
						industry_filter: this.selectedIndustries,
						location_filter: this.selectedLocations,
						employees_filter: this.selectedSizes,
						innovation_net_filter: this.innovationNetEnabled,
					},
				},
			};
		},
	},
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
input:checked ~ .dot {
	transform: translateX(100%);
	background-color: #48bb78;
}
</style>
