import axios from "axios";
import store from "@/store";
import env from "@/environment";

// const BASE_URL = 'https://foresightsi-horizon-search.dev.networkpraxis.com';
// const API_VERSION = 'v1';

const BASE_URL = env.horizonSearchAPIBaseURL;
const API_VERSION = env.horizonSearchAPIVersion;

class FSISearchClient {
	executeSearch(query = "", filters = {}, limit = 10, offset = 0) {
		let resourceURL = "/search/query";
		let payload = {
			query: query,
			limit: limit,
			offset: offset,
		};
		if ("domain_filter" in filters) {
			payload["domain_filter"] = filters.domain_filter;
		}
		if ("industry_filter" in filters) {
			payload["industry_filter"] = filters.industry_filter;
		}
		if ("location_filter" in filters) {
			payload["location_filter"] = filters.location_filter;
		}
		if ("employees_filter" in filters) {
			payload["employees_filter"] = filters.employees_filter;
		}
		if ("innovation_net_filter" in filters) {
			payload["innovation_net_filter"] = filters.innovation_net_filter;
		}
		return this.axios.post(resourceURL, payload);
	}

	constructor() {
		this.baseUrl = `${BASE_URL}/${API_VERSION}`;
		this.init();
	}

	/**
	 * Initialize Axios defaults and API Map
	 */
	init() {
		this.setupAxios();
	}

	/**
	 * Setup axios
	 */
	setupAxios() {
		this.axios = axios.create({ baseURL: this.baseUrl });

		this.axios.interceptors.request.use(
			(config) => {
				const token = store.getters.auth.token;

				if (token) {
					config.headers.Authorization = `Bearer ${token}`;
				} else {
					throw new axios.Cancel(`Operation canceled by the user \n 
                                            Reson: no auth token present`);
				}

				return config;
			},
			(error) => {
				console.log(`No auth token present @ ApiProvider`);
				console.log(error);
				Promise.reject(error);
			}
		);
	}
}

const fsisearchapi = new FSISearchClient();
export default fsisearchapi;
