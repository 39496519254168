<template>
	<VueMultiselect
		@select="updateAdd"
		@remove="updateRemove"
		v-model="selectionModel"
		placeholder="Type to search"
		:options="options"
		:multiple="true"
		:close-on-select="false"
		:showNoResults="false">
	</VueMultiselect>
</template>

<script>
import VueMultiselect from "vue-multiselect";

export default {
	created() {},

	mounted() {
		// console.log('mounted')
		// console.log(this.selection)
		// console.log(this.initialSelection)
	},

	updated() {
		// console.log('updated')
	},

	components: {
		VueMultiselect,
	},

	props: ["options", "initialSelection"],
	emits: ["valueChange"],

	data() {
		return {
			selectionModel: this.initialSelection,
			selection: [],
		};
	},

	methods: {
		updateAdd(item) {
			this.selection.push(item);
			// console.log(this.selection)
			this.$emit("valueChange", this.selection);
		},
		updateRemove(item) {
			this.selection.splice(this.selection.indexOf(item), 1);
			// console.log(this.selection)
			this.$emit("valueChange", this.selection);
		},
	},

	watch: {},

	computed: {},
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style></style>
