<!-- This example requires Tailwind CSS v2.0+ -->
<template>
	<div class="flex flex-col">
		<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
			<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
				<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
					<table class="min-w-full divide-y divide-gray-200">
						<thead class="bg-gray-50">
							<tr>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Name
								</th>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Created at
								</th>
								<th scope="col" class="relative px-6 py-3"></th>
							</tr>
						</thead>
						<tbody class="bg-white divide-y divide-gray-200">
							<tr v-for="row in saved_searches" :key="row.search_id">
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="text-sm text-gray-900">{{ row.name }}</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="text-sm text-gray-900">
										{{ row.formatted_created_at }}
									</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
									<div class="flex flex-row-reverse">
										<div v-if="row.search_id == activeSavedSearchId" class="relative pt-2">
											Active
										</div>
										<button
											v-if="row.search_id != activeSavedSearchId"
											@click="gotoSavedSearch(row.search_id)"
											class="btn btn-default-outline btn-small">
											View
										</button>
										<button
											v-if="activeTeamPermission != 'READ'"
											@click="deleteSavedSearchModal(row.search_id, row.name)"
											class="btn btn-danger-outline btn-small mr-2">
											Delete
										</button>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>

	<Modal v-show="confirmDeleteModal" :widthPercent="50" @close="confirmDeleteModal = false">
		<template v-slot:header>
			<h3>Are you sure you want to delete this saved search?</h3>
		</template>

		<template v-slot:body>
			<div class="my-4">
				Name: <span class="font-bold text-md">{{ deleteSavedSearchName }}</span>
			</div>
			<div class="flex">
				<v-button @click="cancelDeleteSavedSearch" class="btn btn-default mr-3">Cancel</v-button>
				<v-button @click="confirmDeleteSavedSearch" :loading="deletingSavedSearch" class="btn btn-danger"
					>Delete search</v-button
				>
			</div>
		</template>
	</Modal>
</template>

<script>
import fsiprankingapi from "@/services/api-clients/fsipranking";
import moment from "moment";
import Modal from "@/components/Modal";
import Button from "@/components/ui/Button";
import { mapGetters } from "vuex";

export default {
	created() {},

	mounted() {
		this.getSavedSearches();
	},

	components: {
		"v-button": Button,
		Modal,
	},

	data() {
		return {
			saved_searches: [],
			confirmDeleteModal: false,
			deleteSearchId: null,
			deleteSavedSearchName: "",
			deletingSavedSearch: false,
			activeSavedSearchId: this.$route.query.searchId,
		};
	},

	methods: {
		gotoSavedSearch(searchId) {
			this.$router.push({
				name: "workspace-prospect-search",
				query: {
					projectId: this.projectId,
					listId: this.listId,
					searchId: searchId,
				},
			});
		},

		deleteSavedSearchModal(searchId, name) {
			this.confirmDeleteModal = true;
			this.deleteSearchId = searchId;
			this.deleteSavedSearchName = name;
		},
		cancelDeleteSavedSearch() {
			this.confirmDeleteModal = false;
			this.deleteSearchId = null;
		},
		async confirmDeleteSavedSearch() {
			this.deletingSavedSearch = true;
			await this.deleteSavedSearch(this.deleteSearchId);
			this.deletingSavedSearch = false;
			this.confirmDeleteModal = false;
			this.deleteSearchId = null;
			this.deleteSavedSearchName = "";
		},

		async getSavedSearches() {
			try {
				let response = await this.$api
					.resource("prospectProjectSavedSearches", {
						team_id: this.teamId,
						project_type: "prospects",
						project_id: this.projectId,
					})
					.fetchAll();

				for (var i in response.data.saved_searches) {
					response.data.saved_searches[i].formatted_created_at = moment(
						response.data.saved_searches[i].formatted_created_at
					).format("ddd, MMM Do YYYY, HH:mm:ss");
				}
				this.saved_searches = response.data.saved_searches;
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
		},

		async deleteSavedSearch(searchId) {
			try {
				let response = await this.$api
					.resource("prospectProjectSavedSearches", {
						team_id: this.teamId,
						project_id: this.projectId,
					})
					.delete(searchId);
				this.getSavedSearches();
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
		},
	},

	props: ["teamId", "projectId", "listId"],

	computed: {
		...mapGetters(["activeTeamPermission"]),
	},
};
</script>
