<template>
	<div class="flex flex-col">
		<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
			<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
				<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
					<table class="min-w-full divide-y divide-gray-200">
						<thead class="bg-gray-50">
							<tr>
								<th
									scope="col"
									class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Rank
								</th>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Name
								</th>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Employees
								</th>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									InnovationNet Grade
								</th>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Website
								</th>
								<!-- <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Details</span>
                </th> -->
								<th scope="col" class="relative px-3 py-3">
									<span class="sr-only">Actions</span>
								</th>
							</tr>
						</thead>
						<tbody class="bg-white divide-y divide-gray-200">
							<tr
								v-for="item in searchResults"
								:key="item['domain']"
								class="relative hover:bg-gray-100 cursor-pointer"
								@click="viewResult(item.domain)">
								<td class="px-3 py-4 whitespace-nowrap text-center border-r-2">
									<div class="text-sm text-gray-900 font-bold">
										{{ item.rank }}
									</div>
								</td>
								<td class="px-6 py-4 break-normal max-w-md">
									<div class="break-normal text-sm text-gray-900 font-bold">
										{{ item.name ? item.name.toUpperCase() : item.domain.toUpperCase() }}
										<span class="text-xs ml-2 font-normal">{{
											checkLocationLetterCase(item.source.region)
										}}</span>
									</div>
									<!-- <div class="text-xs max-w-sm truncate">
                    <span v-html="item.summary"></span>
                  </div> -->
									<!-- <div class="flex flex-row"> -->
									<!-- <div class="text-xs w-8">{{ item.region }}</div> -->
									<div class="text-xs mt-2">
										{{ checkIndustryLetterCase(item.source.industry) }}
									</div>
									<!-- <div class="text-xs ml-6">{{ item.employees }} employees</div> -->
									<!-- </div> -->
								</td>
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="text-sm text-gray-900">
										{{ item.source.employees }}
									</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap">
									<div
										v-if="item.source.innovation_net_grade == 'A'"
										class="w-10 text-center text-sm rounded-full text-white font-black bg-green-700">
										{{ item.source.innovation_net_grade }}
									</div>
									<div
										v-if="item.source.innovation_net_grade == 'B'"
										class="w-10 text-center text-sm rounded-full text-white font-black bg-green-500">
										{{ item.source.innovation_net_grade }}
									</div>
									<div
										v-if="item.source.innovation_net_grade == 'C'"
										class="w-10 text-center text-sm rounded-full text-white font-black bg-yellow-300">
										{{ item.source.innovation_net_grade }}
									</div>
									<div
										v-if="item.source.innovation_net_grade == 'D'"
										class="w-10 text-center text-sm rounded-full text-white font-black bg-yellow-500">
										{{ item.source.innovation_net_grade }}
									</div>
									<div
										v-if="item.source.innovation_net_grade == 'E'"
										class="w-10 text-center text-sm rounded-full text-white font-black bg-red-600">
										{{ item.source.innovation_net_grade }}
									</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="text-sm text-gray-900">
										<a
											class="underline hover:no-underline text-blue-600"
											@click.stop="visitLink(item.domain)"
											:href="'http://' + item.domain"
											target="_blank"
											>{{ item.domain }}</a
										>
									</div>
								</td>
								<!-- <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-xs font-bold text-center">{{ item.region }}</div>
                  <div class="text-xs font-bold text-center">{{ item.industry }}</div>
                  <div class="text-xs font-bold text-center">Size: {{ item.employees }}</div>
                </td> -->
								<td class="px-3 py-2 whitespace-nowrap text-right text-sm font-medium">
									<div class="flex flex-row">
										<Tooltip text="View match">
											<a class="mr-3" :href="item.url" target="_blank">
												<button
													@click.stop="nothing()"
													class="btn btn-action btn-small opacity-25">
													<span v-html="link_icon"></span>
												</button>
											</a>
										</Tooltip>
										<div v-if="listContext">
											<button
												v-if="!prospectIsTracked(item.domain)"
												@click.stop="addProspect(item.domain)"
												class="btn btn-success btn-small opacity-25">
												<span v-html="plus_icon"></span>
											</button>
											<div class="relative pt-2" v-if="prospectIsTracked(item.domain)">
												Tracked
											</div>
										</div>
									</div>
								</td>
								<!-- <div class="absolute left-0 bottom-0">
                  <div class="flex flex-row">
                    <div class="text-xs font-bold text-center">{{ item.region }}</div>
                    <div class="text-xs font-bold text-center">{{ item.industry }}</div>
                    <div class="text-xs font-bold text-center">Size: {{ item.employees }}</div>
                  </div>
                </div> -->
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import fsiprankingapi from "@/services/api-clients/fsipranking";
import Button from "@/components/ui/Button";
import Tooltip from "@/components/ui/Tooltip";
import { checkLocationLetterCase, checkIndustryLetterCase } from "@/utils";

// import tmp_data_cache from '@/tmp-data-cache.json'

const plus_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>`;
const link_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                    </svg>`;

export default {
	created() {
		// if (this.parameters != null) {
		//   this.getRanks()
		// }
	},

	mounted() {
		if (this.listContext) {
			let _tracked_prospects = {};
			for (let i in this.listContext.prospect_data.prospects) {
				_tracked_prospects[this.listContext.prospect_data.prospects[i].prospect_id] = true;
			}
			this.tracked_prospects = _tracked_prospects;
		}
	},

	components: {
		// 'v-button': Button,
		// Tooltip,
	},

	data() {
		return {
			checkLocationLetterCase,
			checkIndustryLetterCase,
			plus_icon,
			link_icon,
			tracked_prospects: {},
		};
	},

	emits: ["prospectsLoaded"],

	methods: {
		nothing() {},

		viewResult(domain) {
			this.$router.push({
				name: "view-prospect-search-result",
				params: { teamId: this.teamId, resultId: domain },
			});
		},

		visitLink(domain) {
			return;
		},

		addProspect(domain) {
			if (this.listContext) {
				this.addProspectToListContext(domain);
			} else {
				this.addProspectToList(domain);
			}
		},

		async addProspectToListContext(domain) {
			try {
				let response = await this.$api
					.resource("prospectProjectProspectList", {
						team_id: this.listContext.teamId,
						project_id: this.listContext.projectId,
						list_id: this.listContext.listId,
					})
					.create_put({ prospect_id: domain });
				this.tracked_prospects[domain] = true;
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
		},

		addProspectToList(domain) {
			console.log(domain);
		},

		// removeProspect(domain) {
		//   delete this.tracked_prospects[domain];
		// },

		prospectIsTracked(prospect_id) {
			return prospect_id in this.tracked_prospects;
		},

		innovationNetGrade(score) {
			if (score > 0.9) {
				return "A";
			} else if (score > 0.8) {
				return "B";
			} else if (score > 0.6) {
				return "C";
			} else if (score > 0.4) {
				return "D";
			} else {
				return "E";
			}
		},
	},

	props: ["teamId", "searchResults", "listContext"],
};
</script>

<style></style>
